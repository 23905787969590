import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import '../index.css';

const reactSwal = withReactContent(Swal);

function swAlert (text){
    reactSwal.fire({
        icon: 'info',
        text: text
    })
}

async function enterName(){
    const { value: name } = await Swal.fire({
        title: 'Enter your name',
        input: 'text',
        inputLabel: 'your name',
        // inputValue: "enter your name",
        showCancelButton: true,
        inputValidator: (value) => {
          if (!value) {
            return 'You need to enter your name!'
          }
        }
      })
      
      if (name) {
        // Swal.fire(`Your name is ${name}`);
        return name;
      }
}


async function getAvatarList(){
  // const inputOptions = new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       '#ff0000': 'Red',
  //       '#00ff00': 'Green',
  //       '#0000ff': 'Blue'
  //     })
  //   }, 1000)
  // })

<swal-image src="https://avatars.dicebear.com/api/male/john.svg?mood[]=happy&mood[]=sad" width="100px" height="100px" alt="..." />
  const a = {
    '#ff0000': `<br><img src="https://avatars.dicebear.com/api/male/john.svg?mood[]=happy&mood[]=sad" style="width:100px; height:100px; margin:20px"></img>`,
    '#00ff00': `<br><img src="https://avatars.dicebear.com/api/male/john.svg?mood[]=happy&mood[]=sad" style="width:100px; height:100px; margin:20px"></img>`,
    '#0000ff': `<br><img src="https://avatars.dicebear.com/api/male/john.svg?mood[]=happy&mood[]=sad" style="width:100px; height:100px; margin:20px"></img>`,
    'a': `<br><img src="https://avatars.dicebear.com/api/male/john.svg?mood[]=happy&mood[]=sad" style="width:100px; height:100px; margin:20px"></img>`,
    'b0': `<br><img src="https://avatars.dicebear.com/api/male/john.svg?mood[]=happy&mood[]=sad" style="width:100px; height:100px; margin:20px"></img>`,
    '#r': `<br><img src="https://avatars.dicebear.com/api/male/john.svg?mood[]=happy&mood[]=sad" style="width:100px; height:100px; margin:20px"></img>`
  }
  let avatars = ["happy","haha","cry","kiss","wink","bored","neutro","cold","sob","kookoo","surprise","wow","angry","sleep","cynical"];
  // justifyContent: "center", 
  //   alignItems:"center",
  const { value: color } = await Swal.fire({
    title: 'Select avatar',
    // input: 'radio',
    // inputOptions: a,
    html:`<div class="swal2-radio" style="display:flex; justifyContent: center alignItems:center; flex-wrap: wrap; over-flow:auto;">
              <div class="component_border">
                <label><input type="radio" name="swal2-radio" value="#ff0000">
                  <img src="${require("../img/happy.gif")}" style="width:80px; height:80; margin:0px">
                </label>
              </div>
              <div class="component_border">
                <label><input type="radio" name="swal2-radio" value="#00ff00">
                  <img src="${require("../img/haha.gif")}" style="width:80px; height:80; margin:0px">
                </label>
              </div>
              <div class="component_border">
                <label><input type="radio" name="swal2-radio" value="#0000ff">
                  <img src="${require("../img/cry.gif")}" style="width:80px; height:80; margin:0px">
                </label>
              </div>
              <div class="component_border">
                <label><input type="radio" name="swal2-radio" value="#ff0000">
                  <img src="${require("../img/wink.gif")}" style="width:80px; height:80; margin:0px">
                </label>
              </div>
              <div class="component_border">
                <label><input type="radio" name="swal2-radio" value="#00ff00">
                  <img src="${require("../img/bored.gif")}" style="width:80px; height:80; margin:0px">
                </label>
              </div>
              <div class="component_border">
                <label><input type="radio" name="swal2-radio" value="#0000ff">
                  <img src="${require("../img/sleep.gif")}" style="width:80px; height:80; margin:0px">
                </label>
              </div>
              <div class="component_border">
                <label><input type="radio" name="swal2-radio" value="#0000ff">
                  <img src="${require("../img/kiss.gif")}" style="width:80px; height:80; margin:0px">
                </label>
              </div>
              <div class="component_border">
                <label><input type="radio" name="swal2-radio" value="#ff0000">
                  <img src="${require("../img/neutro.gif")}" style="width:80px; height:80; margin:0px">
                </label>
              </div>
              <div class="component_border">
                <label><input type="radio" name="swal2-radio" value="#00ff00">
                  <img src="${require("../img/cold.gif")}" style="width:80px; height:80; margin:0px">
                </label>
              </div>
          </div>`,
      
    inputValidator: (value) => {
      if (!value) {
        return 'You need to choose something!'
      }
    }
  })

  if (color) {
    Swal.fire({ html: `You selected ${"avatar"}` })
  }
}


export { 
    swAlert,
    enterName,
    getAvatarList,
}